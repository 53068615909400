import React, { FC } from 'react';
import { Button, Modal } from 'antd';
import './style.scss';
interface IProps {
  // onToggleModal(): void
  isShowModal: boolean;
  title: string;
  onOk: any;
  onCancel: any;
}

const BaseModalInput: FC<IProps> = (props) => {
  return (
    <Modal
      className="custom-modal"
      visible={props.isShowModal}
      title={null}
      closable={false}
      footer={null}
    >
      <div className="container">
        <div className = "title">{props.title}</div>
        <div className = "body">{props.children}</div>
        <div className = "footer">
          <Button className = "cancel-button footer-button" onClick = {()=>props.onCancel()}>Huỷ</Button>
          <Button className = "ok-button footer-button" onClick = {()=>{props.onOk()}}>Lưu</Button>
        </div>
      </div>
    </Modal>
  );
};

export default BaseModalInput;
