import { INavigation } from "../types/DefineApp";
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import  HomeIcon from "@material-ui/icons/Home";
import  AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import  EmojiObjectsIcon from "@material-ui/icons/EmojiObjects";
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import InsertChartOutlinedTwoToneIcon from '@material-ui/icons/InsertChartOutlinedTwoTone';

export const navigation: INavigation[] = [
  { name: "Trang chủ", to: "/", icon: <HomeIcon /> },
  { name: "Hồ sơ rủi ro", to: "/risk-profile", icon: <AssignmentIndIcon /> },
  { name: "Tài chính của bạn", to: "/personal-finance", icon: <AttachMoneyIcon /> },
  { name: "Cơ cấu tài sản", to: "/asset-mix", icon: <InsertChartOutlinedTwoToneIcon /> },
  { name: "Dòng tiền năm", to: "/cash-flow", icon: <MonetizationOnIcon /> },
  { name: "Tài sản ròng", to: "/net-assets", icon: <i className="fas fa-network-wired"></i> },
  { name: "Kế hoạch tài chính", to: "/financial-planning", icon: <i className="fas fa-file-invoice-dollar"></i> },
  { name: "Khuyến nghị từ chuyên gia", to: "/recommend", icon: <EmojiObjectsIcon /> },
];  
