import React, { Component } from 'react';

import { Button } from 'antd';
import './style.scss';
type IProps = {
  onClick: any;
};
export default class ButtonPlus extends Component<IProps> {
  render() {
    return (
      <div className = "custom-button-plus">
        <Button className="button-plus" onClick = {()=>{this.props.onClick()}}>
          <i className="fas fa-plus icon-plus"></i>
        </Button>
      </div>
    );
  }
}
