import * as React from 'react';
import { IRoute } from './types/DefineApp';
import LoanConsumer from './views/LoanConsumer';
import NetAssets from './views/NetAssetsPage';

// Trang chủ
const HomePage = React.lazy(() => import('./views/HomePage/index'));

// Auth page
// const LoginPage = React.lazy(() => import("./views/AuthPage/Login/index"));
// Finance
const FinancePage = React.lazy(() => import('./views/FinancePage/index'));

// const DemoPage  = React.lazy(() => import('./views/DemoPage/index'));
// Tiền mặt và thành toán
const CashAndSettlementPage = React.lazy(() => import('./views/CashAndSettlementPage/index'));
// Tiền gửi tiết kiệm
const SavedMoneyPage = React.lazy(() => import('./views/SavedMoneyPage/index'));
// Quản lý bất động sản
const RealEstateManagementPage = React.lazy(
  () => import('./views/RealEstateManagementPage/index'),
);
// Chứng chỉ quỹ
const FundCertificatesPage = React.lazy(() => import('./views/FundCertificatesPage/index'));
// Vàng
const GoldPage = React.lazy(() => import('./views/GoldPage/index'));
// Trái phiếu
const BondsPage = React.lazy(() => import('./views/BondsPage/index'));
// Bảo hiểm
const InsurrancePage = React.lazy(() => import('./views/InsurrancePage/index'));
// Cổ phần
const SharePage = React.lazy(() => import('./views/SharePage/index'));
// Cho vay
const LoanAsset = React.lazy(() => import('./views/LoanAsset/index'));
// Tổng thu nhập thường xuyên
const TotalIncomePage = React.lazy(() => import('./views/TotalIncomePage/index'));
// Tổng chi phí thường xuyên
const TotalCostPage = React.lazy(() => import('./views/TotalCostPage/index'));
// Thu nhap tu dau tu
const InvestmentIncome = React.lazy(() => import('./views/InvestmentIncome/index'));
// thu nhap tu kinh doanh
const IncomeBusiness = React.lazy(() => import('./views/IncomeBusiness/index'));
// Vay tín dụng
const CreditPage = React.lazy(() => import('./views/CreditPage/index'));
// vay dinh che tai chinh - no dau tu tai chinh
const BorrowFinance = React.lazy(() => import('./views/BorrowFinance/BorrowFinance'));
// vay no - tai chinh
const LoanFinance = React.lazy(() => import('./views/LoanFinance/index'));
// vay dinh che BDS
const BorrowRealEstate = React.lazy(() => import('./views/BorrowRealEstate/index'));
// vay no BDS
const LoanRealEstate = React.lazy(() => import('./views/LoanRealEstate/index'));
// vay dinh che kinh doan
const BorrowBusiness = React.lazy(() => import('./views/BorrowBusiness/index'));
// vay no kinh doan
const LoanBusiness = React.lazy(() => import('./views/LoanBusiness/index'));
// Vay nợ
const LoanPage = React.lazy(() => import('./views/LoanPage/index'));
//Tài sản tiêu dùng
const ConsumerPropertyPage = React.lazy(() => import('./views/ConsumerPropertyPage/index'));
//Tài sản vo hinh
const AssetsInvisible = React.lazy(() => import('./views/AssetsInvisible/index'));

// danh gia ho so
const RiskProfilePage = React.lazy(() => import('./views/RickProfilePage'));
const QuizPage = React.lazy(() => import('./views/QuizPage'));
const RiskProfileResult = React.lazy(() => import('./views/RiskProfileResult'));

const AssetMixPage = React.lazy(() => import('./views/AssetMixPage'));
const RecommendPage = React.lazy(() => import('./views/RecommendPage'));

const CashFlowPage = React.lazy(() => import('./views/CashFlowPage'));
const FinancialPlanningPage = React.lazy(() => import('./views/FinancialPlanningPage'));


export const routes: IRoute[] = [
  // menu
  { exact: true, path: '/', name: 'Trang chủ', component: HomePage },
  {
    exact: true,
    path: '/personal-finance',
    name: 'Tài chính cá nhân',
    component: FinancePage,
  },
  { exact: true, path: '/asset-mix', name: 'Cơ cấu tài sản', component: AssetMixPage },
  {
    exact: true,
    path: '/recommend',
    name: 'Khuyến nghị từ chuyên gia',
    component: RecommendPage,
  },

  // { exact: true, path: "/login", name: "Đăng nhập", component: LoginPage },

  // { exact: true, path: "/demo", name: "Demo", component: DemoPage },

  //--------------------danh gia ho so--------------------//
  { exact: true, path: '/risk-profile', name: 'Hồ sơ rủi ro', component: RiskProfilePage },
  {
    exact: true,
    path: '/risk-profile/survey',
    name: 'Khảo sát hồ sơ rủi ro',
    component: QuizPage,
  },
  {
    exact: true,
    path: '/risk-profilerisk-profile-result',
    name: 'Đánh giá hồ sơ rủi ro',
    component: RiskProfileResult,
  },
  //--------------------------------------------------//

  //--------------------thu chi thuong xuyen--------------------
  {
    exact: true,
    path: '/personal-finance/total-income',
    name: 'Tổng thu nhập',
    component: TotalIncomePage,
  },
  {
    exact: true,
    path: '/personal-finance/total-cost',
    name: 'Tổng chi phí',
    component: TotalCostPage,
  },
  {
    exact: true,
    path: '/personal-finance/income-investment',
    name: 'Thu nhập từ Tài Sản Đầu tư',
    component: InvestmentIncome,
  },
  {
    exact: true,
    path: '/personal-finance/income-business',
    name: 'Thu nhập từ Kinh doanh',
    component: IncomeBusiness,
  },
  //--------------------------------------------------//

  // --------------------tai san--------------------//
  {
    exact: true,
    path: '/personal-finance/cash-and-payments',
    name: 'Tiền mặt và thanh toán',
    component: CashAndSettlementPage,
  },
  {
    exact: true,
    path: '/personal-finance/saved-money',
    name: 'Tiền gửi tiết kiệm',
    component: SavedMoneyPage,
  },
  {
    exact: true,
    path: '/personal-finance/insurrance',
    name: 'Bảo hiểm',
    component: InsurrancePage,
  },
  { exact: true, path: '/personal-finance/bonds', name: 'Trái phiếu', component: BondsPage },
  {
    exact: true,
    path: '/personal-finance/fund-certificates',
    name: 'Chứng chỉ quỹ',
    component: FundCertificatesPage,
  },
  {
    exact: true,
    path: '/personal-finance/real-estate-management',
    name: 'Quản lý bất động sản',
    component: RealEstateManagementPage,
  },
  { exact: true, path: '/personal-finance/gold', name: 'Vàng', component: GoldPage },
  { exact: true, path: '/personal-finance/share', name: 'Cổ phiếu', component: SharePage },
  { exact: true, path: '/personal-finance/loan-asset', name: 'Cho vay', component: LoanAsset },

  //--------------------------------------------------//

  // --------------------khoan vay--------------------//
  {
    exact: true,
    path: '/personal-finance/credit',
    name: 'Vay tín dụng',
    component: CreditPage,
  },
  { exact: true, path: '/personal-finance/loan', name: 'Vay nợ', component: LoanPage },
  {
    exact: true,
    path: '/personal-finance/borrow-finance',
    name: 'Vay định chế tài chính',
    component: BorrowFinance,
  },
  {
    exact: true,
    path: '/personal-finance/loan-finance',
    name: 'Vay nợ',
    component: LoanFinance,
  },
  {
    exact: true,
    path: '/personal-finance/borrow-real-estate',
    name: 'Vay định chế tài chính',
    component: BorrowRealEstate,
  },
  {
    exact: true,
    path: '/personal-finance/loan-real-estate',
    name: 'Vay nợ',
    component: LoanRealEstate,
  },
  {
    exact: true,
    path: '/personal-finance/borrow-business',
    name: 'Vay định chế tài chính',
    component: BorrowBusiness,
  },
  {
    exact: true,
    path: '/personal-finance/loan-business',
    name: 'Vay nợ',
    component: LoanBusiness,
  },
  {
    exact: true,
    path: '/personal-finance/loan-consumer',
    name: 'Vay tiêu dùng',
    component: LoanConsumer,
  },
  {
    exact: true,
    path: '/personal-finance/consumer-asset',
    name: 'Tài sản tiêu dùng',
    component: ConsumerPropertyPage,
  },
  {
    exact: true,
    path: '/personal-finance/invisible-asset',
    name: 'Tài sản vô hình',
    component: AssetsInvisible,
  },
  //--------------------------------------------------//

  // dòng tiền năm
  { exact: true, path: '/cash-flow', name: 'Dòng tiền năm', component: CashFlowPage },

  // ke hoach tai chinh
  {
    exact: true,
    path: '/financial-planning',
    name: 'Kế hoạch tài chính',
    component: FinancialPlanningPage,
  },

  // Tài sản ròng
  { exact: true, path: '/net-assets', name: 'Tài sản ròng', component: NetAssets },

];
