import React, { Component } from 'react';
import Util from '../../../utils/Util';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

type IProps = { data: any };

type IState = {
  mapTableCashAndSettlement: any;
  CashAndSettlementData: any;
  open: boolean;
};

export class CashAndSettlement extends Component<IProps, IState> {
  constructor(props) {
    super(props);

    this.state = {
      mapTableCashAndSettlement: [
        {
          label: '1.1 Tiền mặt',
          value: {
            HUSBAND: 0,
            OTHER: 0,
            COMMON: 0,
            WIFE: 0,
            SUM: 0,
          },
        },
        {
          label: '1.2 Ngoại tệ quy đổi',
          value: {
            HUSBAND: 0,
            OTHER: 0,
            COMMON: 0,
            WIFE: 0,
            SUM: 0,
          },
        },
        {
          label: '1.3 Giá trị tiền mặt của BHNT',
          value: {
            HUSBAND: 0,
            OTHER: 0,
            COMMON: 0,
            WIFE: 0,
            SUM: 0,
          },
        },
        {
          label: '1.4 Khác',
          value: {
            HUSBAND: 0,
            OTHER: 0,
            COMMON: 0,
            WIFE: 0,
            SUM: 0,
          },
        },
      ],
      CashAndSettlementData: [],
      open: false,
    };
  }

  componentDidMount() {
    this.getCashAndSettlementData();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.data !== this.props.data) {
      this.getCashAndSettlementData();
    }
  }

  async getCashAndSettlementData() {
    if (this.props.data) {
      let data: any = this.props.data.cashAndPayments;
      this.sumCashVND(data);
      this.sumForeignCurrencyConvert(data);
      this.sumInsurrance(this.props.data);
    }
  }

  sumCashVND(data: any) {
    let value = {
      HUSBAND: 0,
      OTHER: 0,
      COMMON: 0,
      WIFE: 0,
      SUM: 0,
    };
    data.forEach((e) => {
      if (e.unit === 'VND') {
        if (e.ownership === 'OTHER') {
          value.OTHER += e.amount;
        }
        if (e.ownership === 'COMMON') {
          value.COMMON += e.amount;
        }
        if (e.ownership === 'WIFE') {
          value.WIFE += e.amount;
        }
        if (e.ownership === 'HUSBAND') {
          value.HUSBAND += e.amount;
        }
      }
      value.SUM = value.OTHER + value.COMMON + value.WIFE + value.HUSBAND;
    });

    let table: any = this.state.mapTableCashAndSettlement;
    table[0] = {
      label: '1.1 Tiền mặt',
      value: value,
    };
    this.setState({
      mapTableCashAndSettlement: table,
    });
  }

  sumForeignCurrencyConvert(data: any) {
    let value = {
      HUSBAND: 0,
      OTHER: 0,
      COMMON: 0,
      WIFE: 0,
      SUM: 0,
    };
    data.forEach((e) => {
      if (e.unit === 'USD') {
        if (e.ownership === 'OTHER') {
          value.OTHER += e.amount * 23051;
        }
        if (e.ownership === 'COMMON') {
          value.COMMON += e.amount * 23051;
        }
        if (e.ownership === 'WIFE') {
          value.WIFE += e.amount * 23051;
        }
        if (e.ownership === 'HUSBAND') {
          value.HUSBAND += e.amount * 23051;
        }
      }
      value.SUM = value.OTHER + value.COMMON + value.WIFE + value.HUSBAND;
    });
    let table: any = this.state.mapTableCashAndSettlement;
    table[1] = {
      label: '1.2 Ngoại tệ quy đổi',
      value: value,
    };

    this.setState({
      mapTableCashAndSettlement: table,
    });
  }

  sumInsurrance(data: any) {
    let value = {
      HUSBAND: 0,
      OTHER: 0,
      COMMON: 0,
      WIFE: 0,
      SUM: 0,
    };
    data.insurances.forEach((e) => {
      if (e.ownership === 'OTHER') {
        value.OTHER += e.amount;
      }
      if (e.ownership === 'COMMON') {
        value.COMMON += e.amount;
      }
      if (e.ownership === 'WIFE') {
        value.WIFE += e.amount;
      }
      if (e.ownership === 'HUSBAND') {
        value.HUSBAND += e.amount;
      }
      value.SUM = value.OTHER + value.COMMON + value.WIFE + value.HUSBAND;
    });
    let table: any = this.state.mapTableCashAndSettlement;
    table[2] = {
      label: '1.3 Giá trị tiền mặt của BHNT',
      value: value,
    };

    this.setState({
      mapTableCashAndSettlement: table,
    });
  }

  sumCashCol(index1, index2, value) {
    let sum = 0;
    sum =
      this.state.mapTableCashAndSettlement[index1].value[`${value}`] +
      this.state.mapTableCashAndSettlement[index2].value[`${value}`] +
      this.state.mapTableCashAndSettlement[2].value[`${value}`];
    return sum;
  }

  setOpen(open: boolean) {
    this.setState({
      open: !open,
    });
  }

  render() {
    return (
      <>
        <tr>
          <td className="font-size-16"></td>
          <td
            onClick={() => this.setOpen(this.state.open)}
            className="btn-show-child-table"
          >
            <IconButton aria-label="expand row" size="small">
              {this.state.open ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
            </IconButton>
            <b>1. Tiền mặt và tài khoản thanh toán</b>
          </td>
          <td>{Util.formatMoneny(this.sumCashCol(0, 1, 'HUSBAND'))}</td>
          <td>{Util.formatMoneny(this.sumCashCol(0, 1, 'WIFE'))}</td>
          <td>{Util.formatMoneny(this.sumCashCol(0, 1, 'COMMON'))}</td>
          <td>{Util.formatMoneny(this.sumCashCol(0, 1, 'OTHER'))}</td>
          <td>{Util.formatMoneny(this.sumCashCol(0, 1, 'SUM'))}</td>
        </tr>

        <TableCell style={{ padding: 0 }} colSpan={7}>
          <Collapse in={this.state.open} timeout="auto" unmountOnExit>
            <Box>
              <Table size="small" aria-label="purchases">
                {this.state.mapTableCashAndSettlement.map((e, i) => (
                  <tr key={i} className="custom-my-td">
                    <td></td>
                    <td>{e.label}</td>
                    <td>{Util.formatMoneny(e.value.HUSBAND)}</td>
                    <td>{Util.formatMoneny(e.value.WIFE)}</td>
                    <td>{Util.formatMoneny(e.value.COMMON)}</td>
                    <td>{Util.formatMoneny(e.value.OTHER)}</td>
                    <td>{Util.formatMoneny(e.value.SUM)}</td>
                  </tr>
                ))}
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </>
    );
  }
}

export default CashAndSettlement;
