export default class Util {
  public static omitObject(data: object) {
    let response = {};
    Object.keys(data).forEach((item) => {
      if (data[item]) response[item] = data[item];
    });
    return response;
  }

  public static formatBytes(bytes, decimals = 2) {
    if (bytes === 0) {
      return '0 Bytes';
    }
    let k = 1024;
    let dm = decimals < 0 ? 0 : decimals;
    let sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    let i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  public static formatMoneny(moneny: any) {
    if (typeof moneny != 'number') {
      return '0';
    }
    return `${moneny
      .toFixed(0)
      .toString()
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}`;
  }

  public static splitDecimals(moneny: any) {
    return `${Math.round(moneny * 100) / 100}`;
  }

  public static timeDifference(stime: number, etime: number) {
    let hour = 60 * 60 * 1000;
    let day = hour * 24;
    let month = day * 30;
    let ms = Math.abs(stime - etime);
    let months = parseInt(`${ms / month}`, 10);
    ms -= months * month;
    let days = parseInt(`${ms / day}`, 10);
    ms -= days * day;
    let hours = parseInt(`${ms / hour}`, 10);
    ms -= hours * hour;
    return { days, hours, months };
  }
}
