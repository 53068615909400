export const ListRouteName: any = [
  { path: '/', name: 'Trang chủ' },
  { path: '/personal-finance', name: 'Tài chính cá nhân' },
  { path: '/asset-mix', name: 'Cơ cấu tài sản' },
  { path: '/recommend', name: 'Khuyến nghị từ chuyên gia' },

  { path: '/login', name: 'Đăng nhập' },

  //--------------------danh gia ho so--------------------//
  { path: '/risk-profile', name: 'Hồ sơ rủi ro' },

  { path: '/survey', name: 'Khảo sát hồ sơ rủi ro' },
  { path: '/risk-profile/survey', name: 'Khảo sát hồ sơ rủi ro' },

  // { path: 'risk-profile-result', name: 'Đánh giá hồ sơ rủi ro' },
  { path: '/risk-profilerisk-profile-result', name: 'Đánh giá hồ sơ rủi ro' },
  //--------------------------------------------------//

  //--------------------thu chi thuong xuyen--------------------
  { path: '/personal-finance/total-income', name: 'Tổng thu nhập' },
  { path: '/total-income', name: 'Tổng thu nhập' },
  { path: '/personal-finance/total-cost', name: 'Tổng chi phí' },
  { path: '/total-cost', name: 'Tổng chi phí' },
  
  //--------------------------------------------------//

  // --------------------tai san--------------------//
  { path: '/cash-and-payments', name: 'Tiền mặt và thanh toán' },
  { path: '/personal-finance/cash-and-payments', name: 'Tiền mặt và thanh toán' },

  { path: '/saved-money', name: 'Tiền gửi tiết kiệm' },
  { path: '/personal-finance/saved-money', name: 'Tiền gửi tiết kiệm' },

  { path: '/insurrance', name: 'Bảo hiểm' },
  { path: '/personal-finance/insurrance', name: 'Bảo hiểm' },

  { path: '/bonds', name: 'Trái phiếu' },
  { path: '/personal-finance/bonds', name: 'Trái phiếu' },

  { path: '/fund-certificates', name: 'Chứng chỉ quỹ' },
  { path: '/personal-finance/fund-certificates', name: 'Chứng chỉ quỹ' },

  { path: '/real-estate-management', name: 'Quản lý bất động sản' },
  { path: '/personal-finance/real-estate-management', name: 'Quản lý bất động sản' },

  { path: '/gold', name: 'Vàng' },
  { path: '/personal-finance/gold', name: 'Vàng' },

  { path: '/share', name: 'Cổ Phiếu' },
  { path: '/personal-finance/share', name: 'Cổ Phiếu' },
  //--------------------------------------------------//

  // --------------------khoan vay--------------------//
  { path: '/personal-finance/credit', name: 'Vay tín dụng' },
  { path: '/credit', name: 'Vay tín dụng' },
  { path: '/personal-finance/loan', name: 'Vay nợ' },
  { path: '/loan', name: 'Vay nợ' },
  //--------------------------------------------------//

  // dòng tiền năm
  { path: '/cash-flow', name: 'Dòng tiền năm' },

  // ke hoach tai chinh
  { path: '/financial-planning', name: 'Kế hoạch tài chính' },

];
