import React, { Component } from 'react';
import './style.scss';
import ButtonPlus from '../../Components/ButtonPlus/index';
import BaseModalInput from '../../Components/BaseModalInput';
import { Form, InputNumber, Select, message, Button, DatePicker, Row, Col, Input } from 'antd';
import {
  AxiosGetSecretApi,
  AxiosPostSecretApi,
  AxiosPutSecretApi,
  AxiosDelSecretApi,
} from '../../utils/Api';
import ModalWarning from '../../Components/ModalWarning';
import moment from 'moment';
import CardInfo from '../../Components/CardInfo';
import Util from '../../utils/Util';

type IProps = {
  history: any;
};
type IState = {
  // data
  creditData: any;

  // edit
  selectedCredit: any;
  isEdit: boolean;

  sum: number;

  // modal
  isShowModalAdd: boolean;
  isShowModalWarning: boolean;
};

export default class LoanConsumer extends Component<IProps, IState> {
  form: any;
  listCreditForm: any;
  listCardTitle: any;
  listCardValue: any;
  constructor(props) {
    super(props);

    this.state = {
      // data
      creditData: [],

      // edit
      selectedCredit: {},
      isEdit: false,

      // modal
      isShowModalAdd: false,
      isShowModalWarning: false,

      sum: 0,
    };
    this.form = React.createRef();

    this.listCreditForm = [
      {
        name: 'Vay tiêu dùng',
        value: 'CONSUMER_LOAN',
      },
      {
        name: 'Vay mua ô tô',
        value: 'CAR_LOAN',
      },
      {
        name: 'Vay mua nhà',
        value: 'HOME_LOAN',
      },
    ];

    this.listCardTitle = ['Hình thức', 'Chủ sở hữu', 'Giá trị', 'Lãi suất (% năm)'];
    this.listCardValue = ['convertForm', 'ownership', 'convertAmount', 'convertInterestRate'];
  }

  componentDidMount() {
    this.getDataCredit();
  }

  async getDataCredit() {
    let res = await AxiosGetSecretApi('/loan/me?type[eq]=LOAN');
    if (res && res.status === 'Success') {
      let data = res.data.filter((o) => o.form === 'CONSUMER_LOAN' || o.form === 'CAR_LOAN' || o.form === 'HOME_LOAN');
      let sum = 0;
      data.forEach((e) => {
        if (e.unit === 'USD') {
          sum += e.amount * 23051;
        } else {
          sum += e.amount;
        }
      });
      let creditData: any = [];
      data.forEach((e) => {
        creditData.push({
          ...e,
          convertForm: this.getFormName(e.form),
          convertAmount: `${Util.formatMoneny(e.amount)} ${e.unit}`,
          convertInterestRate: `${e.interestRate} % năm`,
        });
      });
      this.setState({
        creditData: creditData,
        sum: sum,
      });
    }
  }

  getFormName(name: any) {
    for (let i = 0; i < this.listCreditForm.length; i++) {
      if (this.listCreditForm[i].value === name) {
        return this.listCreditForm[i].name;
      }
    }
  }

  // modal
  handelShowModalAdd = () => {
    this.setState({ isShowModalAdd: true });
  };

  handleCancelModalAdd = () => {
    this.setState({
      isShowModalAdd: false,
      isEdit: false,
    });
  };

  handleShowModalWarning = () => {
    this.setState({ isShowModalWarning: true });
  };

  handleCancelModalWarning = () => {
    this.setState({ isShowModalWarning: false });
  };

  submitForm() {
    this.form.current
      .validateFields()
      .then((e) => {
        let data = {
          type: 'LOAN',
          unit: e.unit,
          form: e.form,
          amount: e.amount,
          organization: e.organization,
          startDate: new Date(e.startDate.format()).getTime(),
          maturityDate: new Date(e.maturityDate.format()).getTime(),
          interestRate: e.interestRate,
          ownership: e.ownership,
        };

        if (this.state.isEdit) {
          this.updateData(data);
        } else {
          this.sendData(data);
        }
      })
      .catch();
  }

  async sendData(data: any) {
    let res = await AxiosPostSecretApi('/loan/add', data);
    if (res && res.status) {
      this.getDataCredit();
      this.setState({
        isShowModalAdd: false,
        isEdit: false,
      });
      message.success('Thêm khoản vay thành công');
    } else {
      message.error('Thêm khoản vay không thành công');
    }
  }

  async updateData(data: any) {
    let res = await AxiosPutSecretApi(`/loan/${this.state.selectedCredit.id}`, data);
    if (res && res.status) {
      this.getDataCredit();
      this.setState({
        isShowModalAdd: false,
        isEdit: false,
      });
      message.success('Chỉnh sửa khoản vay thành công');
    } else {
      message.error('Chỉnh sửa khoản vay không thành công');
    }
  }

  async deleteCredit() {
    let res = await AxiosDelSecretApi(`/loan/${this.state.selectedCredit.id}`);

    if (res && res.status === 'Success') {
      this.getDataCredit();
      this.setState({
        isEdit: false,
        isShowModalWarning: false,
        isShowModalAdd: false,
      });
      message.success('Xoá khoản vay thành công');
    } else {
      message.error('Xoá khoản vay không thành công');
    }
  }

  handleEdit(e: any) {
    let data = e;
    this.setState({
      isEdit: true,
      selectedCredit: {
        type: 'LOAN',
        unit: data.unit,
        form: data.form,
        amount: data.amount,
        organization: data.organization,
        startDate: moment(data.startDate),
        maturityDate: moment(data.maturityDate),
        interestRate: data.interestRate,
        id: data._id,
        ownership: data.ownership,
      },
      isShowModalAdd: true,
    });
  }

  render() {
    return (
      <div className="default-page">
        <div style={{ width: '100%' }}>
          <div className="button">
            <ButtonPlus onClick={this.handelShowModalAdd}></ButtonPlus>
          </div>

          <br />
          {this.state.creditData.length !== 0 ? (
            <div className="wrap-content">
              <div>
                <div className="font-size-16 border-bottom-title">Chồng</div>
                <Row gutter={[20, 20]}>
                  {this.state.creditData
                    .filter((el) => el.ownership === 'HUSBAND')
                    .map((e, i) => (
                      <Col lg={{ span: 12 }} span={24} key={i}>
                        <div className="info">
                          <CardInfo
                            data={e}
                            listTitle={this.listCardTitle}
                            listValue={this.listCardValue}
                            name={'Hình thức'}
                            highlight={''}
                            onClick={() => {
                              this.handleEdit(e);
                            }}
                          ></CardInfo>
                        </div>
                      </Col>
                    ))}
                </Row>
              </div>
              <div>
                <div className="font-size-16 border-bottom-title">Vợ</div>
                <Row gutter={[20, 20]}>
                  {this.state.creditData
                    .filter((el) => el.ownership === 'WIFE')
                    .map((e, i) => (
                      <Col lg={{ span: 12 }} span={24} key={i}>
                        <div className="info">
                          <CardInfo
                            data={e}
                            listTitle={this.listCardTitle}
                            listValue={this.listCardValue}
                            name={'Hình thức'}
                            highlight={''}
                            onClick={() => {
                              this.handleEdit(e);
                            }}
                          ></CardInfo>
                        </div>
                      </Col>
                    ))}
                </Row>
              </div>

              <div>
                <div className="font-size-16 border-bottom-title">Chung</div>
                <Row gutter={[20, 20]}>
                  {this.state.creditData
                    .filter((el) => el.ownership === 'COMMON')
                    .map((e, i) => (
                      <Col lg={{ span: 12 }} span={24} key={i}>
                        <div className="info">
                          <CardInfo
                            data={e}
                            listTitle={this.listCardTitle}
                            listValue={this.listCardValue}
                            name={'Hình thức'}
                            highlight={''}
                            onClick={() => {
                              this.handleEdit(e);
                            }}
                          ></CardInfo>
                        </div>
                      </Col>
                    ))}
                </Row>
              </div>

              <div>
                <div className="font-size-16 border-bottom-title">Khác</div>
                <Row gutter={[20, 20]}>
                  {this.state.creditData
                    .filter((el) => el.ownership === 'OTHER')
                    .map((e, i) => (
                      <Col lg={{ span: 12 }} span={24} key={i}>
                        <div className="info">
                          <CardInfo
                            data={e}
                            listTitle={this.listCardTitle}
                            listValue={this.listCardValue}
                            name={'Hình thức'}
                            highlight={''}
                            onClick={() => {
                              this.handleEdit(e);
                            }}
                          ></CardInfo>
                        </div>
                      </Col>
                    ))}
                </Row>
              </div>
            </div>
          ) : (
            <div>
              <i>
                Bạn chưa nhập thông tin các khoản vay nợ, vui lòng nhập thông tin (nếu có) để
                nhận được khuyến nghị chính xác nhất
              </i>
            </div>
          )}
        </div>

        {this.state.isShowModalAdd && (
          <BaseModalInput
            title={this.state.isEdit ? 'Chỉnh sửa khoản vay' : 'Thêm mới khoản vay'}
            isShowModal={this.state.isShowModalAdd}
            onOk={() => {
              this.submitForm();
            }}
            onCancel={() => {
              this.handleCancelModalAdd();
            }}
          >
            <Form layout="vertical" ref={this.form} className="form-quiz">
              <Form.Item
                label={<div>Hình thức</div>}
                name="form"
                style={{ marginBottom: '20px' }}
                hasFeedback
                rules={[{ required: true, message: 'Trường bắt buộc nhập' }]}
                initialValue={this.state.isEdit ? this.state.selectedCredit.form : undefined}
              >
                <Select className="custom-select">
                  {this.listCreditForm.map((e, i) => (
                    <Select.Option key={i} value={e.value}>
                      {e.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item
                label={<div>Giá trị</div>}
                name="amount"
                style={{ marginBottom: '20px' }}
                hasFeedback
                rules={[{ required: true, message: 'Trường bắt buộc nhập' }]}
                initialValue={this.state.isEdit ? this.state.selectedCredit.amount : undefined}
              >
                <InputNumber
                  className="custom-input-number form-input"
                  formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  style={{ width: '100%' }}
                  min={1}
                  onBlur={() => {
                    if (typeof this.form?.current?.getFieldValue()?.amount === 'number') {
                      this.form.current.setFieldsValue({
                        amount: +this.form.current?.getFieldValue().amount?.toFixed(0),
                      });
                    }
                  }}
                />
              </Form.Item>

              <Form.Item
                label={<div>Tiền tệ</div>}
                name="unit"
                style={{ marginBottom: '20px' }}
                hasFeedback
                rules={[{ required: true, message: 'Trường bắt buộc nhập' }]}
                initialValue={this.state.isEdit ? this.state.selectedCredit.unit : undefined}
              >
                <Select className="custom-select">
                  <Select.Option value="VND">VND</Select.Option>
                  <Select.Option value="USD">USD</Select.Option>
                </Select>
              </Form.Item>

              <Form.Item
                label={<div>Tổ chức</div>}
                name="organization"
                style={{ marginBottom: '20px' }}
                hasFeedback
                rules={[{ required: true, message: 'Trường bắt buộc nhập' }]}
                initialValue={
                  this.state.isEdit ? this.state.selectedCredit.organization : undefined
                }
              >
                <Input className="custom-input form-input" autoComplete="off" />
              </Form.Item>

              <Form.Item
                label={<div>Thời gian vay</div>}
                name="startDate"
                style={{ marginBottom: '20px' }}
                hasFeedback
                rules={[{ required: true, message: 'Trường bắt buộc nhập' }]}
                initialValue={
                  this.state.isEdit ? this.state.selectedCredit.startDate : undefined
                }
              >
                <DatePicker
                  className="custom-date form-input"
                  style={{ width: '100%' }}
                  placeholder=""
                />
              </Form.Item>

              <Form.Item
                label={<div>Thời kết thúc</div>}
                name="maturityDate"
                style={{ marginBottom: '20px' }}
                hasFeedback
                rules={[{ required: true, message: 'Trường bắt buộc nhập' }]}
                initialValue={
                  this.state.isEdit ? this.state.selectedCredit.maturityDate : undefined
                }
              >
                <DatePicker
                  className="custom-date form-input"
                  style={{ width: '100%' }}
                  placeholder=""
                />
              </Form.Item>

              <Form.Item
                label={<div>Lãi suất áp dụng %</div>}
                name="interestRate"
                style={{ marginBottom: '20px' }}
                hasFeedback
                rules={[{ required: true, message: 'Trường bắt buộc nhập' }]}
                initialValue={
                  this.state.isEdit ? this.state.selectedCredit.interestRate : undefined
                }
              >
                <InputNumber
                  className="custom-input-number form-input"
                  formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  style={{ width: '100%' }}
                  min={0}
                />
              </Form.Item>

              <Form.Item
                label={<div>Chủ sở hữu</div>}
                name="ownership"
                style={{ marginBottom: '20px' }}
                hasFeedback
                rules={[{ required: true, message: 'Trường bắt buộc nhập' }]}
                initialValue={
                  this.state.isEdit ? this.state.selectedCredit.ownership : undefined
                }
              >
                <Select className="custom-select">
                  <Select.Option value="HUSBAND">Chồng</Select.Option>
                  <Select.Option value="WIFE">Vợ</Select.Option>
                  <Select.Option value="COMMON">Chung</Select.Option>
                  <Select.Option value="OTHER">Khác</Select.Option>
                </Select>
              </Form.Item>

              {this.state.isEdit && (
                <div className="delete-button">
                  <Button
                    type="link"
                    danger
                    onClick={() => {
                      this.setState({
                        isShowModalWarning: true,
                      });
                    }}
                  >
                    XOÁ KHOẢN VAY
                  </Button>
                </div>
              )}
            </Form>
          </BaseModalInput>
        )}
        {this.state.isShowModalWarning && (
          <ModalWarning
            isShowModal={this.state.isShowModalWarning}
            title={`Xác nhận xoá khoản vay`}
            onCancel={() => {
              this.setState({ isShowModalWarning: false });
            }}
            onOk={() => {
              this.deleteCredit();
            }}
          >
            <div>
              {this.getFormName(this.state.selectedCredit.form)} (
              {Util.formatMoneny(this.state.selectedCredit.amount)}{' '}
              {this.state.selectedCredit.unit})
            </div>
          </ModalWarning>
        )}
      </div>
    );
  }
}
