import React, { Component } from 'react';
import Util from '../../../utils/Util';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

type IProps = {
  data: any;
};

type IState = {
  mapInvestmentAssets: any;
  investmentAssets: any;
  open: boolean;
};

export default class InvestmentAssets extends Component<IProps, IState> {
  constructor(props) {
    super(props);

    this.state = {
      investmentAssets: [],
      mapInvestmentAssets: [
        {
          label: '2.1 Tiền gửi tiết kiệm ',
          value: {
            HUSBAND: 0,
            OTHER: 0,
            COMMON: 0,
            WIFE: 0,
            SUM: 0,
          },
        },
        {
          label: '2.2 Chứng chỉ quỹ',
          value: {
            HUSBAND: 0,
            OTHER: 0,
            COMMON: 0,
            WIFE: 0,
            SUM: 0,
          },
        },
        {
          label: '2.3 Cổ phiếu',
          value: {
            HUSBAND: 0,
            OTHER: 0,
            COMMON: 0,
            WIFE: 0,
            SUM: 0,
          },
        },
        {
          label: '2.4 Trái phiếu',
          value: {
            HUSBAND: 0,
            OTHER: 0,
            COMMON: 0,
            WIFE: 0,
            SUM: 0,
          },
        },
        {
          label: '2.5 Vàng',
          value: {
            HUSBAND: 0,
            OTHER: 0,
            COMMON: 0,
            WIFE: 0,
            SUM: 0,
          },
        },
        {
          label: '2.6 Đầu tư doanh nghiệp',
          value: {
            HUSBAND: 0,
            OTHER: 0,
            COMMON: 0,
            WIFE: 0,
            SUM: 0,
          },
        },
        {
          label: '2.7 Khác',
          value: {
            HUSBAND: 0,
            OTHER: 0,
            COMMON: 0,
            WIFE: 0,
            SUM: 0,
          },
        },
      ],
      open: false,
    };
  }

  componentDidMount() {
    this.getDataInvestmentAssets();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.data !== this.props.data) {
      this.getDataInvestmentAssets();
    }
  }

  async getDataInvestmentAssets() {
    if (this.props.data) {
      let data = this.props.data;
      this.setState({
        investmentAssets: data,
      });
      //  tiền  gửi tiết kiệm
      this.onConvertState('amount', data.savings, 0);
      //  Chứng chỉ quỹ
      this.onConvertState('totalMarketValue', data.fundCertificates, 1);
      // Cổ phiếu
      this.onConvertState('totalMarketValue', data.stocks, 2);
      // Trái phiếu
      this.onConvertState('totalPurchasePrice', data.bonds, 3);
      // vang
      this.onConvertState('totalMarketValue', data.golds, 4);
    }
  }

  onConvertState(propety, data, index) {
    let value = {
      HUSBAND: 0,
      OTHER: 0,
      COMMON: 0,
      WIFE: 0,
      SUM: 0,
    };

    data.forEach((e) => {
      if (e.ownership === 'OTHER') {
        if (e.unit === 'USD') {
          value.OTHER += e[propety] * 23051;
        } else if (e.unit === 'VND') {
          value.OTHER += e[propety];
        }
      }
      if (e.ownership === 'COMMON') {
        if (e.unit === 'USD') {
          value.COMMON += e[propety] * 23051;
        } else {
          value.COMMON += e[propety];
        }
      }
      if (e.ownership === 'WIFE') {
        if (e.unit === 'USD') {
          value.WIFE += e[propety] * 23051;
        } else {
          value.WIFE += e[propety];
        }
      }
      if (e.ownership === 'HUSBAND') {
        if (e.unit === 'USD') {
          value.HUSBAND += e[propety] * 23051;
        } else {
          value.HUSBAND += e[propety];
        }
      }
      value.SUM = value.OTHER + value.COMMON + value.WIFE + value.HUSBAND;
    });

    let table: any = this.state.mapInvestmentAssets;
    table[index] = {
      label: table[index].label,
      value: value,
    };

    this.setState({
      mapInvestmentAssets: table,
    });
  }

  // tính tổng (cột)
  sumCashCol(value) {
    let sum = 0;
    sum =
      this.state.mapInvestmentAssets[0].value[`${value}`] +
      this.state.mapInvestmentAssets[1].value[`${value}`] +
      this.state.mapInvestmentAssets[2].value[`${value}`] +
      this.state.mapInvestmentAssets[3].value[`${value}`] +
      this.state.mapInvestmentAssets[4].value[`${value}`];
    return sum;
  }

  setOpen(open: boolean) {
    this.setState({
      open: !open,
    });
  }

  render() {
    return (
      <>
        <tr>
          <td></td>
          <td onClick={() => this.setOpen(this.state.open)} className="btn-show-child-table">
            <IconButton aria-label="expand row" size="small">
              {this.state.open ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
            </IconButton>
            <b>2. Tài sản đầu tư</b>
          </td>
          <td>{Util.formatMoneny(this.sumCashCol('HUSBAND'))}</td>
          <td>{Util.formatMoneny(this.sumCashCol('WIFE'))}</td>
          <td>{Util.formatMoneny(this.sumCashCol('COMMON'))}</td>
          <td>{Util.formatMoneny(this.sumCashCol('OTHER'))}</td>
          <td>{Util.formatMoneny(this.sumCashCol('SUM'))}</td>
        </tr>

        <TableCell style={{ padding: 0 }} colSpan={7}>
          <Collapse in={this.state.open} timeout="auto" unmountOnExit>
            <Box>
              <Table size="small" aria-label="purchases">
                {this.state.mapInvestmentAssets.map((e, i) => (
                  <tr key={i} className="custom-my-td">
                    <td></td>
                    <td>{e.label}</td>
                    <td>{Util.formatMoneny(e.value.HUSBAND)}</td>
                    <td>{Util.formatMoneny(e.value.WIFE)}</td>
                    <td>{Util.formatMoneny(e.value.COMMON)}</td>
                    <td>{Util.formatMoneny(e.value.OTHER)}</td>
                    <td>{Util.formatMoneny(e.value.SUM)}</td>
                  </tr>
                ))}
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </>
    );
  }
}
