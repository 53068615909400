import React, { Component } from 'react';
import Util from '../../../utils/Util';

type IProps = { data: any };

type IState = {
  value: any;
  asset: number;
};

export default class SumPresentAsset extends Component<IProps, IState> {
  listAsset: any;
  constructor(props) {
    super(props);

    this.state = {
      value: {
        HUSBAND: 0,
        OTHER: 0,
        COMMON: 0,
        WIFE: 0,
        SUM: 0,
      },

      asset: 0,
    };

    this.listAsset = [
      {
        title: 'bonds',
        value: 'totalPurchasePrice',
      },

      {
        title: 'cashAndPayments',
        value: 'amount',
      },

      {
        title: 'fundCertificates',
        value: 'totalMarketValue',
      },
      {
        title: 'golds',
        value: 'totalMarketValue',
      },
      {
        title: 'insurances',
        value: 'amount',
      },
      //   {
      //     title: 'realEstates',
      //     value: 'marketPrice',
      //   },
      {
        title: 'savings',
        value: 'amount',
      },
      {
        title: 'stocks',
        value: 'totalMarketValue',
      },
    ];
  }

  componentDidMount() {
    if (this.props.data) this.getSumData();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.data !== this.props.data) this.getSumData();
  }

  getSumData() {
    let value: any = {
      HUSBAND: 0,
      OTHER: 0,
      COMMON: 0,
      WIFE: 0,
      SUM: 0,
    };
    let data: any = this.props.data;
    this.listAsset.forEach((e) => {
      data[`${e.title}`].forEach((o) => {
        if (o.ownership === 'OTHER') {
          value.OTHER += o.unit === 'USD' ? o[e.value] * 23051 : o[e.value];
        }
        if (o.ownership === 'COMMON') {
          value.COMMON += o.unit === 'USD' ? o[e.value] * 23051 : o[e.value];
        }
        if (o.ownership === 'WIFE') {
          value.WIFE += o.unit === 'USD' ? o[e.value] * 23051 : o[e.value];
        }
        if (o.ownership === 'HUSBAND') {
          value.HUSBAND += o.unit === 'USD' ? o[e.value] * 23051 : o[e.value];
        }
      });
    });

    let asset: any = value.HUSBAND + value.WIFE + value.COMMON + value.OTHER;
    this.setState({
      value: value,
      asset: asset,
    });
  }

  render() {
    return (
      <tr>
        <td className="font-size-16" style={{ textAlign: 'center' }}>
          <b>Tài sản có (VND)</b>
        </td>
        <td style={{ textAlign: 'end' }}></td>
        <td>
          <b>{Util.formatMoneny(this.state.value.HUSBAND)}</b>
        </td>
        <td>
          <b>{Util.formatMoneny(this.state.value.WIFE)}</b>
        </td>
        <td>
          <b>{Util.formatMoneny(this.state.value.COMMON)}</b>
        </td>
        <td>
          <b>{Util.formatMoneny(this.state.value.OTHER)}</b>
        </td>
        <td>
          <b>{Util.formatMoneny(this.state.asset)}</b>
        </td>
      </tr>
    );
  }
}
