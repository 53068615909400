import React, { Component } from 'react';
import ConsumerLoans from './ConsumerLoans';
import LoanInvestmentAccount from './LoanInvestmentAccount';
import LoanPaymentAccount from './LoanPaymentAccount';
import RealEstateLoans from './RealEstateLoans';
import SumPresentCredit from './SumPresentCredit';

type IProps = {
  data: any;
};

type IState = {};

export default class DebtAssets extends Component<IProps, IState> {
  render() {
    return (
      <>
        <SumPresentCredit data={this.props.data} />

        {/*  Vay Tài Khoản Thanh toán */}
        <LoanPaymentAccount loanPayment={this.props.data} />

        {/* Vay tài khoản đầu tư */}
        <LoanInvestmentAccount loanConsumer={this.props.data} />

        {/* vay tiêu dùng */}
        <ConsumerLoans loanConsumer={this.props.data} />

        {/* vay bất động sản */}
        <RealEstateLoans loanConsumer={this.props.data} />
      </>
    );
  }
}
