// import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { useHistory } from 'react-router-dom';
// header
// import AccountCircle from '@material-ui/icons/AccountCircle';

// content
import { Container } from '@material-ui/core';
import React, { Suspense, useEffect, useRef } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { routes } from '../Routes';
import { IRoute } from '../types/DefineApp';

// menu
import { Link } from 'react-router-dom';
import { navigation } from './MenuConfig';
import { ListRouteName } from './ListRouteName';
import Button from '@material-ui/core/Button';
// version
import { Version } from '../utils/Version';
const drawerWidth = 270;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },

  title: { flexGrow: 1, color: 'white' },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
}));

export default function PersistentDrawerLeft() {
  const classes = useStyles();
  const history = useHistory();
  const [open, setOpen] = React.useState(localStorage.token ? true : false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  const getSelected = () => {
    let selected: number = 0;
    for (let i = 0; i < navigation.length; i++) {
      if (window.location.hash === `#${navigation[i].to}`) {
        selected = i;
        break;
      }
    }
    return selected;
  };
  const [selectedIndex, setSelectedIndex] = React.useState(getSelected());
  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);
  };
  // const [isLogged, setisLogged] = React.useState(localStorage.token ? true : false);
  // const [userName, setUserName] = React.useState(
  //   localStorage.username ? localStorage.username : '',
  // );

  const loading = (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '75vh',
      }}
    ></div>
  );

  const ListMenu = () => (
    <div style={{ width: '100%' }} role="presentation">
      <List className="sidebar-material">
        {navigation.map((item, index) => (
          <Link to={item.to} key={index}>
            <ListItem
              button
              key={index}
              selected={selectedIndex === index}
              onClick={(event) => handleListItemClick(event, index)}
            >
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText primary={item.name} className="menu-item" />
            </ListItem>
          </Link>
        ))}
      </List>
    </div>
  );

  const getLocationName = () => {
    let location: string = window.location.hash;
    location = location.replaceAll('#', '');
    let name: string = '';
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].path === location) {
        name = routes[i].name;
        break;
      }
    }
    return name;
  };

  const clickgetBreadcrumb = (name: any) => {
    let path: any = '';
    for (let i = 0; i < ListRouteName.length; i++) {
      if (ListRouteName[i].name === name) {
        path = ListRouteName[i].path;
        break;
      }
    }
    history.push(`${path}`);
  };

  const getBreadcrumb = () => {
    let location: string = window.location.hash;
    location = location.replaceAll('#/', '');
    let listLocation: any = location.split('/');
    let listLocationName: any = [];
    listLocation.forEach((e) => {
      for (let i = 0; i < ListRouteName.length; i++) {
        if (`/${e}` === ListRouteName[i].path) {
          if (ListRouteName[i].name !== 'Trang chủ')
            listLocationName.push(ListRouteName[i].name);
          break;
        }
      }
    });

    return (
      <div style={{ display: 'flex' }}>
        {listLocationName.map((e, i) => (
          <>
            {i === 0 ? (
              <div style={{ display: 'flex' }} key={i}>
                <div
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    history.push('/');
                  }}
                >
                  Trang chủ &nbsp;
                </div>

                {e !== getLocationName() ? (
                  <span>
                    <i className="fas fa-chevron-right" style={{ fontSize: '11px' }} /> &nbsp;{' '}
                    <span
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        clickgetBreadcrumb(e);
                      }}
                    >
                      {e}
                    </span>
                  </span>
                ) : (
                  ''
                )}
              </div>
            ) : (
              <div style={{ display: 'flex' }} key={i}>
                &nbsp;{' '}
                {e !== getLocationName() ? (
                  <div>
                    <i className="fas fa-chevron-right" style={{ fontSize: '11px' }} /> &nbsp;{' '}
                    <div
                      onClick={() => {
                        clickgetBreadcrumb(e);
                      }}
                    >
                      {e}
                    </div>
                  </div>
                ) : (
                  ''
                )}
              </div>
            )}
          </>
        ))}
      </div>
    );
  };

  // resize
  const setSelectedIndexMenu = () => {
    for (let i = 0; i < navigation.length; i++) {
      if (history.location.pathname.includes(navigation[i].to)) {
        setSelectedIndex(i);
      }
    }
  };

  useEffect(() => {
    setSelectedIndexMenu();
  });

  const inputEl = useRef(null);
  let [width, setWidth] = React.useState(window.screen.width);
  useEffect(() => {
    const resizeListener = () => {
      let x: any = inputEl.current || { offsetWidth: 0 };
      setWidth(x.offsetWidth);
    };
    window.addEventListener('resize', resizeListener);
    return () => {
      window.removeEventListener('resize', resizeListener);
    };
  }, []);

  const logout = () => {
    // handleDrawerClose();
    // setisLogged(false);
    // setUserName('');
    history.push('/login');
  };

  return (
    <>
      <div style={{ width: '100%' }} ref={inputEl}></div>

      <div className={classes.root} style={{ backgroundColor: '#f3f7fc', height: '100vh' }}>
        <CssBaseline />
        <AppBar
          position="fixed"
          className={
            width > 600
              ? clsx(classes.appBar, {
                  [classes.appBarShift]: open,
                })
              : ''
          }
          style={{ height: '64px' }}
        >
          <Toolbar style={{ height: '64px' }}>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              // onClick={open ? handleDrawerClose : handleDrawerOpen}
              edge="start"
              className={clsx(classes.menuButton, open && classes.hide)}
            >
              <MenuIcon />
            </IconButton>

            <div style={{ width: '100%' }}>
              <Typography variant="h6" className={classes.title}>
                {getLocationName()}
              </Typography>
              <div>{getBreadcrumb()}</div>
            </div>

            <div style={{ display: 'flex', minWidth: 'max-content', alignItems: 'center' }}>
              <div className="version">v{Version}</div>
              <div className="userName font-size-15">{localStorage.username ? localStorage.username: ""}</div>

              <Button
                style={{
                  color: 'white',
                  border: '1px solid white',
                  padding: '3px',
                  marginLeft: '10px',
                  fontSize: '12px',
                }}
                onClick={() => {
                  logout();
                }}
              >
                <div style={{ alignItems: 'center', height: '18px' }}>Đăng xuất</div>
              </Button>
            </div>
          </Toolbar>
        </AppBar>

        <Drawer
          className={classes.drawer}
          variant={width > 600 ? 'persistent' : 'temporary'}
          anchor="left"
          open={open}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <div className={classes.drawerHeader}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                width: '100%',
                padding: '10px 0px 10px 20px',
              }}
            >
              <img src="/logoAFA.png" alt=" " style={{ height: '48px', width: 'auto' }}></img>
              <IconButton onClick={handleDrawerClose} style = {{width: "48px"}}>
                <i
                  className="fas fa-bars"
                  style={{ transform: 'rotate(90deg)', fontSize: '20px' }}
                ></i>
              </IconButton>
            </div>
          </div>
          <Divider />
          <div style={{ height: '100%' }}>
            <ListMenu></ListMenu>
          </div>

          <div style={{ width: '100%', textAlign: 'right', paddingRight: '10px' }}>
            <IconButton onClick={handleDrawerClose}>
              <i className="fas fa-angle-double-left"></i>
            </IconButton>
          </div>
        </Drawer>

        <main
          className={
            width > 600
              ? clsx(classes.content, {
                  [classes.contentShift]: open,
                })
              : 'wrap-main'
          }
          style={{ minHeight: '100vh', padding: '0' }}
        >
          {/* <div className={classes.drawerHeader} /> */}
          <div style={{ height: '64px' }}> </div>

          <div style={{ height: 'calc(100% - 64px)', padding: '20px', overflow: 'auto' }}>
            <Container style={{ padding: '0px' }}>
              <Suspense fallback={loading}>
                <Switch>
                  {routes.map((route: IRoute, idx) => {
                    return (
                      route.component && (
                        <Route
                          key={idx}
                          path={route.path}
                          exact={route.exact}
                          render={(props) => <route.component {...props} />}
                        />
                      )
                    );
                  })}
                  <Redirect from="/" to="/" />
                </Switch>
              </Suspense>
            </Container>
          </div>
        </main>
      </div>
    </>
  );
}
