import React, { Component } from 'react';
import Util from '../../utils/Util';
import './style.scss';
type IProps = {
  data: any;
  name: any;
  listTitle: any;
  listValue: any;
  highlight: any;
  onClick: any;
};
type IState = {
  data: any;
  name: any;
  listTitle: any;
  listValue: any;
  highlight: any;
  unit: string;
};

const convertOwnership = (type: any) => {
  if (type === 'HUSBAND') {
    return 'Chồng';
  }
  if (type === 'OTHER') {
    return 'Khác';
  }
  if (type === 'WIFE') {
    return 'Vợ';
  }
  if (type === 'COMMON') {
    return 'Chung';
  }
};

export default class CardInfo extends Component<IProps, IState> {
  constructor(props) {
    super(props);

    this.state = {
      data: {},
      name: '',
      listTitle: [],
      listValue: [],
      highlight: '',
      unit: '',
    };
  }

  componentDidMount() {
    this.setState({
      data: this.props.data || {},
      name: this.props.name || '',
      listTitle: this.props.listTitle || [],
      listValue: this.props.listValue || [],
      highlight: this.props.highlight || '',
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.data !== this.props.data) {
      this.setState({
        data: this.props.data || {},
        name: this.props.name || '',
        listTitle: this.props.listTitle || [],
        listValue: this.props.listValue || [],
        highlight: this.props.highlight || '',
      });
    }
  }

  render() {
    return (
      <div className="card-info">
        {this.state.listTitle.map((e, i) => (
          <div className="group font-size-16" key={i}>
            <div className="title">
              {e === this.state.name ? this.state.data[`${this.state.listValue[i]}`] : e}
            </div>
            {e === this.state.name ? (
              <div
                className="edit-icon"
                onClick={() => {
                  this.props.onClick();
                }}
              >
                <i className="far fa-edit"></i>
              </div>
            ) : (
              <div
                className={
                  e === this.state.highlight
                    ? typeof this.state.data[`${this.state.listValue[i]}`] === 'number'
                      ? this.state.data[`${this.state.listValue[i]}`] < 0
                        ? 'value-red'
                        : 'value-green'
                      : typeof this.state.data[`${this.state.listValue[i]}`] === 'string'
                      ? this.state.data[`${this.state.listValue[i]}`].includes('-')
                        ? 'value-red'
                        : 'value-green'
                      : 'value'
                    : 'value'
                }
              >
                {typeof this.state.data[`${this.state.listValue[i]}`] === 'number'
                  ? Util.formatMoneny(this.state.data[`${this.state.listValue[i]}`])
                  : this.state.listValue[i] === 'ownership'
                  ? convertOwnership(this.state.data[`${this.state.listValue[i]}`])
                  : this.state.data[`${this.state.listValue[i]}`]}

                {/* {this.state.unit ? this.state.unit : "VND"} */}
              </div>
            )}
          </div>
        ))}
      </div>
    );
  }
}
