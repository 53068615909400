import React, { Component } from 'react';
import Util from '../../utils/Util';

type IProps = { dataLoan: any; dataAsset: any };

type IState = {
  valueLoan: any;
  credit: number;
  valueAsset: any;
  asset: number;
};

export default class SumNetAsset extends Component<IProps, IState> {
  listAsset: any;
  listCredit: any;
  constructor(props) {
    super(props);

    this.state = {
      valueLoan: {
        HUSBAND: 0,
        OTHER: 0,
        COMMON: 0,
        WIFE: 0,
        SUM: 0,
      },

      credit: 0,
      valueAsset: {
        HUSBAND: 0,
        OTHER: 0,
        COMMON: 0,
        WIFE: 0,
        SUM: 0,
      },

      asset: 0,
    };

    this.listCredit = [
      //   'CONSUMER_LOAN',
      'REAL_ESTATE_LOAN',
      //   'BUSINESS_LOAN',
      'HOME_LOAN',
      'CAR_LOAN',
      'CERDIT_LOAN',
      'OVERDRAFT_LOAN',
    ];

    this.listAsset = [
      {
        title: 'bonds',
        value: 'totalPurchasePrice',
      },

      {
        title: 'cashAndPayments',
        value: 'amount',
      },

      {
        title: 'fundCertificates',
        value: 'totalMarketValue',
      },
      {
        title: 'golds',
        value: 'totalMarketValue',
      },
      {
        title: 'insurances',
        value: 'amount',
      },
      //   {
      //     title: 'realEstates',
      //     value: 'marketPrice',
      //   },
      {
        title: 'savings',
        value: 'amount',
      },
      {
        title: 'stocks',
        value: 'totalMarketValue',
      },
    ];
  }

  componentDidMount() {
    if (this.props.dataLoan) this.getSumDataCredit();
    if (this.props.dataAsset) this.getSumData();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.dataLoan !== this.props.dataLoan) this.getSumDataCredit();
    if (prevProps.dataAsset !== this.props.dataAsset) this.getSumData();
  }

  getSumDataCredit() {
    let value: any = {
      HUSBAND: 0,
      OTHER: 0,
      COMMON: 0,
      WIFE: 0,
      SUM: 0,
    };
    let data: any = this.props.dataLoan;
    data.forEach((e) => {
      if (this.listCredit.includes(e.form)) {
        if (e.ownership === 'OTHER') {
          value.OTHER += e.unit === 'USD' ? e.amount * 23051 : e.amount;
        }
        if (e.ownership === 'COMMON') {
          value.COMMON += e.unit === 'USD' ? e.amount * 23051 : e.amount;
        }
        if (e.ownership === 'WIFE') {
          value.WIFE += e.unit === 'USD' ? e.amount * 23051 : e.amount;
        }
        if (e.ownership === 'HUSBAND') {
          value.HUSBAND += e.unit === 'USD' ? e.amount * 23051 : e.amount;
        }
      }
    });

    let credit: any = value.HUSBAND + value.WIFE + value.COMMON + value.OTHER;
    this.setState({
      valueLoan: value,
      credit: credit,
    });
  }

  getSumData() {
    let value: any = {
      HUSBAND: 0,
      OTHER: 0,
      COMMON: 0,
      WIFE: 0,
      SUM: 0,
    };
    let data: any = this.props.dataAsset;
    this.listAsset.forEach((e) => {
      data[`${e.title}`].forEach((o) => {
        if (o.ownership === 'OTHER') {
          value.OTHER += o.unit === 'USD' ? o[e.value] * 23051 : o[e.value];
        }
        if (o.ownership === 'COMMON') {
          value.COMMON += o.unit === 'USD' ? o[e.value] * 23051 : o[e.value];
        }
        if (o.ownership === 'WIFE') {
          value.WIFE += o.unit === 'USD' ? o[e.value] * 23051 : o[e.value];
        }
        if (o.ownership === 'HUSBAND') {
          value.HUSBAND += o.unit === 'USD' ? o[e.value] * 23051 : o[e.value];
        }
      });
    });

    let asset: any = value.HUSBAND + value.WIFE + value.COMMON + value.OTHER;
    this.setState({
      valueAsset: value,
      asset: asset,
    });
  }

  render() {
    return (
      <tr className="unborder">
        <td
          className="font-size-16"
          style={{ textAlign: 'center', borderRadius: '0 0 0 10px' }}
        >
          <b>Tổng tài sản</b>
        </td>
        <td style={{ textAlign: 'end' }}></td>
        <td>
          <b>
            {Util.formatMoneny(this.state.valueLoan.HUSBAND + this.state.valueAsset.HUSBAND)}
          </b>
        </td>
        <td>
          <b>{Util.formatMoneny(this.state.valueLoan.WIFE + this.state.valueAsset.WIFE)}</b>
        </td>
        <td>
          <b>
            {Util.formatMoneny(this.state.valueLoan.COMMON + this.state.valueAsset.COMMON)}
          </b>
        </td>
        <td>
          <b>{Util.formatMoneny(this.state.valueLoan.OTHER + this.state.valueAsset.OTHER)}</b>
        </td>
        <td style={{ borderRadius: '0 0 10px 0' }}>
          <b>{Util.formatMoneny(this.state.asset + this.state.credit)}</b>
        </td>
      </tr>
    );
  }
}
