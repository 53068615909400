import axios from "axios";
import { createBrowserHistory } from "history";

// const baseUrl = "http://localhost:8000/v1"
const baseUrl = "https://api.wi.afacapital.vn/v1"


const API = axios.create({
  headers: {
    "Content-Type": "application/json",
  },
  timeout: 30000,
});

API.interceptors.request.use((request) => {
  let token = localStorage.getItem("token") ? localStorage.getItem("token") : undefined;
  request.headers["Authorization"] = token;
  return request;
});

API.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error && error.response && error.response.status) {
      switch (error.response.status) {
        case 400:
          return error.response;
        case 401:
          createBrowserHistory().push("/#/login");
          window.location.reload()
          localStorage.clear()
          break;
        case 422:
          error["errMsg"] = "Vui lòng kiểm tra lại dữ liệu";
          break;
        default:
          return Promise.reject({ response: error });
      }
      return Promise.reject({ response: error });
    }
  }
);


export default API;

export async function AxiosPostSecretApi(url, data: any) {
  try {
    let { data: result } = await API.post(baseUrl+url, data);
    return result;
  } catch (error) {
    return error
  }
}

export async function AxiosGetSecretApi(url) {
  try {
    let { data: result } = await API.get(baseUrl+url);
    return result;
  } catch (error) {
    return error
  }
}

export async function AxiosDelSecretApi(url) {
  try {
    let { data: result } = await API.delete(baseUrl+url);
    return result;
  } catch (error) {
    return error
  }
}

export async function AxiosPutSecretApi(url, data) {
  try {
    let { data: result } = await API.put(baseUrl+url, data);
    return result;
  } catch (error) {
    return error
  }
}
