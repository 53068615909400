import React, { Component } from 'react';
import CashAndSettlement from './CashAndSettlement';
import InvestmentAssets from './InvestmentAssets';
import PersonalAssetRealRstate from './PersonalAssetRealRstate';
import SumPresentAsset from './SumPresentAsset';

type IProps = {
  data: any;
};

type IState = {};

export default class PresentAsset extends Component<IProps, IState> {
  render() {
    return (
      <>
        <SumPresentAsset data={this.props.data} />
        <CashAndSettlement data={this.props.data} />
        <InvestmentAssets data={this.props.data} />
        <PersonalAssetRealRstate data={this.props.data} />
      </>
    );
  }
}
