import React from 'react';
import GlobalLayout from './containers/GlobalLayout';
import { HashRouter, Route, Switch } from 'react-router-dom';
import './scss/style.scss';
import './scss/responsive.scss';
import 'antd/dist/antd.css';

const Login = React.lazy(() => import('./views/AuthPage/Login'));
const UnauthenticatedUser = React.lazy(() => import('./views/UnauthenticatedUserPage'));

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

const App = () => {
  return (
    <HashRouter>
      <React.Suspense fallback={loading}>
        <Switch>
          <Route
            exact
            path="/unauthenticated-user"
            name="Tài khoản chưa xác thực"
            render={(props) => <UnauthenticatedUser {...props} />}
          />
          <Route
            exact
            path="/login"
            name="Login Page"
            render={(props) => <Login {...props} />}
          />
          <Route path="/" render={(props) => <GlobalLayout {...props} />} />
        </Switch>
      </React.Suspense>
    </HashRouter>
  );
};

export default App;
