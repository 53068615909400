import React, { Component } from 'react';
import { AxiosGetSecretApi } from '../../utils/Api';
import PresentAsset from './PresentAsset';
import PresentCredit from './PresentCredit';

import './style.scss';
import SumNetAsset from './SumNetAsset';

type IProps = {};
type IState = {
  allAsset: any;
  tableData: any;
  loanData: any;
};
export class NetAssets extends Component<IProps, IState> {
  constructor(props) {
    super(props);

    this.state = {
      allAsset: undefined,
      tableData: {
        cash: {
          husband: 0,
          wife: 0,
          common: 0,
          other: 0,
        },
      },
      loanData: undefined,
    };
  }
  componentDidMount() {
    this.getAllAsset();
    this.getCashAndSettlementData();
  }

  async getAllAsset() {
    let res = await AxiosGetSecretApi('/asset/me');
    if (res && res.status === 'Success') {
      this.setState({
        allAsset: res.data,
      });
    }
  }

  async getCashAndSettlementData() {
    let res = await AxiosGetSecretApi('/loan/me');
    if (res && res.status === 'Success') {
      this.setState({
        loanData: res.data,
      });
    }
  }

  render() {
    return (
      <div className="net-asset">
        <table>
          <thead>
            <tr>
              <th></th>
              <th></th>
              <th>Chồng</th>
              <th>Vợ</th>
              <th>Chung</th>
              <th>Khác</th>
              <th>Tổng</th>
            </tr>
          </thead>

          <tbody>
            <PresentAsset data={this.state.allAsset} />
            <PresentCredit data={this.state.loanData} />
            <SumNetAsset dataAsset={this.state.allAsset} dataLoan={this.state.loanData} />
          </tbody>
        </table>
      </div>
    );
  }
}

export default NetAssets;
