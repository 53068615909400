import React, { Component } from 'react';
import Util from '../../../utils/Util';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

type IProps = {
  loanPayment: any;
};

type IState = {
  mapLoanPaymentAccountData: any;
  open: boolean;
};

export default class LoanPaymentAccount extends Component<IProps, IState> {
  constructor(props) {
    super(props);

    this.state = {
      mapLoanPaymentAccountData: [
        {
          label: '1.1 Vay thấu chi',
          value: {
            HUSBAND: 0,
            OTHER: 0,
            COMMON: 0,
            WIFE: 0,
            SUM: 0,
          },
        },
        {
          label: '1.2 Vay thẻ tín dụng',
          value: {
            HUSBAND: 0,
            OTHER: 0,
            COMMON: 0,
            WIFE: 0,
            SUM: 0,
          },
        },
      ],
      open: false,
    };
  }

  onConvertState(type: string, data: any, index: number) {
    let value = {
      HUSBAND: 0,
      OTHER: 0,
      COMMON: 0,
      WIFE: 0,
      SUM: 0,
    };

    data.forEach((e) => {
      if (e.form === type) {
        if (e.ownership === 'OTHER') {
          if (e.unit === 'USD') {
            value.OTHER += e.amount * 23051;
          } else {
            value.OTHER += e.amount;
          }
        }
        if (e.ownership === 'COMMON') {
          if (e.unit === 'USD') {
            value.COMMON += e.amount * 23051;
          } else {
            value.COMMON += e.amount;
          }
        }
        if (e.ownership === 'WIFE') {
          if (e.unit === 'USD') {
            value.WIFE += e.amount * 23051;
          } else {
            value.WIFE += e.amount;
          }
        }
        if (e.ownership === 'HUSBAND') {
          if (e.unit === 'USD') {
            value.HUSBAND += e.amount * 23051;
          } else {
            value.HUSBAND += e.amount;
          }
        }
      }
      value.SUM = value.OTHER + value.COMMON + value.WIFE + value.HUSBAND;
    });

    let table: any = this.state.mapLoanPaymentAccountData;
    table[index] = {
      label: table[index].label,
      value: value,
    };

    this.setState({
      mapLoanPaymentAccountData: table,
    });
  }

  async getCashAndSettlementData() {
    if (this.props.loanPayment) {
      this.onConvertState('OVERDRAFT_LOAN', this.props.loanPayment, 0);
      this.onConvertState('CERDIT_LOAN', this.props.loanPayment, 1);
    }
  }

  componentDidMount() {
    this.getCashAndSettlementData();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.loanPayment !== this.props.loanPayment) {
      this.getCashAndSettlementData();
    }
  }

  sumLoanCol(index1, index2, value) {
    let sum = 0;
    sum =
      this.state.mapLoanPaymentAccountData[index1].value[`${value}`] +
      this.state.mapLoanPaymentAccountData[index2].value[`${value}`];
    return sum;
  }

  setOpen(open: boolean) {
    this.setState({
      open: !open,
    });
  }

  render() {
    return (
      <>
        <tr>
          <td className="font-size-16" style={{ textAlign: 'center' }}></td>
          <td onClick={() => this.setOpen(this.state.open)} className="btn-show-child-table">
            <IconButton aria-label="expand row" size="small">
              {this.state.open ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
            </IconButton>
            <b>1. Vay Tài Khoản Thanh toán</b>
          </td>
          <td>{Util.formatMoneny(this.sumLoanCol(0, 1, 'HUSBAND'))}</td>
          <td>{Util.formatMoneny(this.sumLoanCol(0, 1, 'WIFE'))}</td>
          <td>{Util.formatMoneny(this.sumLoanCol(0, 1, 'COMMON'))}</td>
          <td>{Util.formatMoneny(this.sumLoanCol(0, 1, 'OTHER'))}</td>
          <td>{Util.formatMoneny(this.sumLoanCol(0, 1, 'SUM'))}</td>
        </tr>

        <TableCell style={{ padding: 0 }} colSpan={7}>
          <Collapse in={this.state.open} timeout="auto" unmountOnExit>
            <Box>
              <Table size="small" aria-label="purchases">
                {this.state.mapLoanPaymentAccountData.map((e, i) => (
                  <tr key={i} className="custom-my-td">
                    <td></td>
                    <td>{e.label}</td>
                    <td>{Util.formatMoneny(e.value.HUSBAND)}</td>
                    <td>{Util.formatMoneny(e.value.WIFE)}</td>
                    <td>{Util.formatMoneny(e.value.COMMON)}</td>
                    <td>{Util.formatMoneny(e.value.OTHER)}</td>
                    <td>{Util.formatMoneny(e.value.SUM)}</td>
                  </tr>
                ))}
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </>
    );
  }
}
