import React, { Component } from 'react';
import Util from '../../../utils/Util';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

type IProps = {
  // dataAssets: any;
  data: any;
};

type IState = {
  personalAssetRealRstate: any;
  open: boolean;
};

export default class PersonalAssetRealRstate extends Component<IProps, IState> {
  constructor(props) {
    super(props);

    this.state = {
      personalAssetRealRstate: [
        {
          label: '3.1 Tài sản cá nhân',
          value: {
            HUSBAND: 0,
            OTHER: 0,
            COMMON: 0,
            WIFE: 0,
            SUM: 0,
          },
        },
        {
          label: '3.2 Bất động sản để ở',
          value: {
            HUSBAND: 0,
            OTHER: 0,
            COMMON: 0,
            WIFE: 0,
            SUM: 0,
          },
        },
        {
          label: '3.3 Bất động sản đầu tư',
          value: {
            HUSBAND: 0,
            OTHER: 0,
            COMMON: 0,
            WIFE: 0,
            SUM: 0,
          },
        },
      ],
      open: false,
    };
  }

  sumCashCol(value) {
    let sum = 0;
    sum =
      this.state.personalAssetRealRstate[0].value[`${value}`] +
      this.state.personalAssetRealRstate[1].value[`${value}`] +
      this.state.personalAssetRealRstate[2].value[`${value}`];
    return sum;
  }

  setOpen(open: boolean) {
    this.setState({
      open: !open,
    });
  }

  render() {
    return (
      <>
        <tr>
          <td></td>
          <td onClick={() => this.setOpen(this.state.open)} className="btn-show-child-table">
            <IconButton aria-label="expand row" size="small">
              {this.state.open ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
            </IconButton>
            <b>3. Tài sản cá nhân và bất động sản</b>
          </td>
          <td>{Util.formatMoneny(this.sumCashCol('HUSBAND'))}</td>
          <td>{Util.formatMoneny(this.sumCashCol('WIFE'))}</td>
          <td>{Util.formatMoneny(this.sumCashCol('COMMON'))}</td>
          <td>{Util.formatMoneny(this.sumCashCol('OTHER'))}</td>
          <td>{Util.formatMoneny(this.sumCashCol('SUM'))}</td>
        </tr>

        <TableCell style={{ padding: 0 }} colSpan={7}>
          <Collapse in={this.state.open} timeout="auto" unmountOnExit>
            <Box>
              <Table size="small" aria-label="purchases">
                {this.state.personalAssetRealRstate.map((e, i) => (
                  <tr key={i} className="custom-my-td">
                    <td></td>
                    <td>{e.label}</td>
                    <td>{Util.formatMoneny(e.value.HUSBAND)}</td>
                    <td>{Util.formatMoneny(e.value.WIFE)}</td>
                    <td>{Util.formatMoneny(e.value.COMMON)}</td>
                    <td>{Util.formatMoneny(e.value.OTHER)}</td>
                    <td>{Util.formatMoneny(e.value.SUM)}</td>
                  </tr>
                ))}
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </>
    );
  }
}
