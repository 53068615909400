import { Button, Modal } from 'antd';
import React, { Component } from 'react';
import './modal-warning.scss';

type IProps = {
//   history: any;
  isShowModal: boolean
  title: any
  onCancel: any
  onOk: any

};
type IState = {
};

export default class ModalWarning extends Component<IProps, IState> {
  render() {
    return (
      <div>
        <Modal
          title={null}
          footer={null}
          closable={false}
          centered
          visible={this.props.isShowModal}
          width="375px"
          className="modal-warning-custom"
        >
          <div>
            <div>
              <div className="modal-title font-size-18">
                <div className="modal-title-icon">
                  <i className="fas fa-exclamation-circle" />
                </div>

                <div className="modal-title-text">{this.props.title}</div>
              </div>

              <div className = "modal-body font-size-16">
                  {this.props.children}
              </div>

              <div className = "warning-text">
                <i>Dữ liệu bạn xoá không thể khôi phục</i>
              </div>

              <div className="modal-footer">
                <Button className="modal-button button-no" onClick={this.props.onCancel}>
                  Không
                </Button>
                <Button
                  className="modal-button button-yes"
                  type="primary"
                  danger
                  onClick={this.props.onOk}
                >
                  Có
                </Button>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}
