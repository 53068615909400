import React, { Component } from 'react';
import Util from '../../../utils/Util';

type IProps = { data: any };

type IState = {
  value: any;
  credit: number;
};

export default class SumPresentCredit extends Component<IProps, IState> {
  listCredit: any;
  constructor(props) {
    super(props);

    this.state = {
      value: {
        HUSBAND: 0,
        OTHER: 0,
        COMMON: 0,
        WIFE: 0,
        SUM: 0,
      },

      credit: 0,
    };

    this.listCredit = [
      //   'CONSUMER_LOAN',
      //   'REAL_ESTATE_LOAN',
      //   'BUSINESS_LOAN',
      'CAR_LOAN',
      'CERDIT_LOAN',
      'OVERDRAFT_LOAN',
    ];
  }

  componentDidMount() {
    if (this.props.data) this.getSumData();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.data !== this.props.data) this.getSumData();
  }

  getSumData() {
    let value: any = {
      HUSBAND: 0,
      OTHER: 0,
      COMMON: 0,
      WIFE: 0,
      SUM: 0,
    };
    let data: any = this.props.data;
    data.forEach((e) => {
      if (this.listCredit.includes(e.form)) {
        if (e.ownership === 'OTHER') {
          value.OTHER += e.unit === 'USD' ? e.amount * 23051 : e.amount;
        }
        if (e.ownership === 'COMMON') {
          value.COMMON += e.unit === 'USD' ? e.amount * 23051 : e.amount;
        }
        if (e.ownership === 'WIFE') {
          value.WIFE += e.unit === 'USD' ? e.amount * 23051 : e.amount;
        }
        if (e.ownership === 'HUSBAND') {
          value.HUSBAND += e.unit === 'USD' ? e.amount * 23051 : e.amount;
        }
      }
    });

    let credit: any = value.HUSBAND + value.WIFE + value.COMMON + value.OTHER;
    this.setState({
      value: value,
      credit: credit,
    });
  }

  render() {
    return (
      <tr>
        <td>
          <b> Tài sản nợ (VND)</b>
        </td>
        <td></td>
        <td>
          <b>{Util.formatMoneny(this.state.value.HUSBAND)}</b>
        </td>
        <td>
          <b>{Util.formatMoneny(this.state.value.WIFE)}</b>
        </td>
        <td>
          <b>{Util.formatMoneny(this.state.value.COMMON)}</b>
        </td>
        <td>
          <b>{Util.formatMoneny(this.state.value.OTHER)}</b>
        </td>
        <td>
          <b>{Util.formatMoneny(this.state.credit)}</b>
        </td>
      </tr>
    );
  }
}
